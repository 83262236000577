@import 'variables';

@import '~owl.carousel/src/scss/owl.carousel';
@import '~owl.carousel/src/scss/owl.theme.default';
@import "~bootstrap-sass/assets/stylesheets/_bootstrap";
@import '~simple-line-icons/scss/simple-line-icons';
@import '~animate.css/animate';
@import '~magnific-popup/dist/magnific-popup';

html {
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif;
  background-color: #000;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 600;
}

.mt-0 {
  margin-top: 0
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-0 {
  margin-bottom: 0
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.pt-0 {
  padding-top: 0
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-0 {
  padding-bottom: 0
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}

.hidden {
  visibility: hidden;
}

.navbar {
  width: 100%;
  border-radius:0;
  border-bottom: 0;
  z-index: 9999;
  position: fixed;
  top:0;
  background-color: rgba(10, 10, 10, 0.5);
  margin: 0;
  z-index: 999999;
}

.logo {
  .logo {
    max-height: 70px;
    transition: max-height 0.5s;
    max-width: none;
    position: absolute;
    opacity: 1;
    margin-top: 15px;
  }
  .logo-blanco {
    max-height: 70px;
    transition: max-height 0.5s;
    /*max-width: none;*/
    margin-top: 15px;
  }
}
.scrolled {
  .logo {
    .logo-white {
      max-height: 65px;
      transition: max-height 0.4s, opacity 0.3s;
      opacity: 0;
      margin-top: 15px;
    }
    .logo-color {
      max-height: 65px;
      transition: max-height 0.4s, opacity 0.3s;
      margin-top: 15px;
    }
  }
}
.scrolled {
  .logo {
    img {
      max-height: 35px;
      transition: max-height 0.4s;
      position: absolute;
      margin-top: 13px;
      &:hover {
        max-height: 65px;
        transition: max-height 0.4s;
      }
    }
  }
  ul.menu {
    li {
      a {
        width: 100%;
        -webkit-transition: all ease-in-out 0.4s;
        -moz-transition: all ease-in-out 0.4s;
        -o-transition: all ease-in-out 0.4s;
        -ms-transition: all ease-in-out 0.4s;
        padding: 23px 15px;
      }
    }
    .sub-menu {
      top: 67px;
      a {
        padding: 10px 20px;
      }
    }
  }
}
ul.menu {
  float: right;
  margin:0;
  transition: all 0.35s ease;
  height: auto;
  padding-left: 0;
  li{
    list-style: none;
    float: left;
    position: relative;
    a {
      font-family: $subtitulo-font;
      font-size: 15px;
      color: #fff;
      font-weight: 700;
      text-align: center;
      list-style: none;
      text-decoration: none !important;
      text-transform: uppercase;
      display: block;
      float: left;
      position: relative;
      padding: 40px 15px;
      margin: 0;
      -webkit-transition: all ease-in-out 0.4s;
      -moz-transition: all ease-in-out 0.4s;
      -o-transition: all ease-in-out 0.4s;
      -ms-transition: all ease-in-out 0.4s;
      &:hover {
        background-color: rgba(0,0,0,0.8);
        color: $color-primario;
        transition: all .4s;
      }
    }
    .red-social {
      .fab{
        color: $color-primario;
        font-size: 18px;
        transition: all 0.35s ease;
        &:hover{
          color: #edeef0;
          transition: all .4s;
        }
      }
    }
  }
  .sub-menu {
    visibility: hidden;
    width: 220px;
    position: absolute;
    top: 102px;
    left: 0px;
    opacity: 0;
    padding-left: 0;
    -webkit-transition: visibility .2s ease, top .2s ease, bottom .2s ease, background .2s ease, margin .2s ease, opacity .2s ease;
    transition: visibility .2s ease, top .2s ease, bottom .2s ease, background .2s ease, margin .2s ease, opacity .2s ease;
    li {
      width: 100%;
    }
  }
  .sub-menu a {
    padding: 10px 20px;
    background-color: rgba(0,0,0,1)!important;
    width: 100%;
    text-transform: inherit;
    padding-right: 13px;
    padding-left: 13px;
  }
  .sub-menu a:hover {
    background-color: #111!important;
    -webkit-transition: color .5s ease, background .5s ease, border .5s ease, opacity .5s ease-in-out;
    transition: color .5s ease, background .5s ease, border .5s ease, opacity .5s ease-in-out;
  }
  .menu-desplegable:hover .sub-menu {
    visibility: visible;
    margin-top: 0px;
    opacity: 1;
  }
  .menu-desplegable > a {
    padding-right: 18px;
  }
  .menu-desplegable .sub-menu > li a {
    padding-right: 18px;
  }
  .scrolled .sub-menu {
    position: absolute;
    top: 92px !important;
  }

  li.active > a {
    color: $color-primario;
    border-bottom: 0;
  }
  li.active > a {
    &:hover {
      color: $color-primario;
      border-bottom: 0;
      &::before {
        width: 100%;
      }
      &::after {
        width: 100%;
      }
    }
  }
}

.fas{
  color: #fff;
  padding-left: 7px;
}
.menu-toggle{
  display: none;
  padding: 1em;
  position: absolute;
  top: .5em;
  right: .5em;
}
.hamburger {
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  display: none;
  cursor: pointer;
  margin: 0;
  padding: 15px 15px;
  overflow: visible;
  outline: none;
  float: right;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  position: relative;
  top: 3px;
  &:hover {
    opacity: 0.7;
  }
}
.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  &::before,
  &::after {
    content: "";
    display: block;
    background-color: $color-primario;
    width: 30px;
    height: 3px;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before {
    top: -10px;
  }
  &::after {
    bottom: -10px;
  }
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: $color-primario;
  width: 30px;
  height: 3px;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger.is-active {
  .hamburger-inner {
  background-color: $color-primario;
    &:before,
    &:after {
      background-color: $color-primario;
    }
  }
  &:hover {
    opacity: 0.7;
  }
}

.hamburger--collapse-r {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }
    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--collapse-r.is-active {
  .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
     }
    &::before {
      top: 0;
      transform: rotate(90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}

.text-container  {
  color: #eee;
  padding-bottom: 40px;
  border-bottom: 1px solid #333;
  p {
    font-size: 16px;
    line-height: 26px;
  }
  ul {
    padding-left: 18px;
    li {
      list-style-type: square;
      font-size: 16px;
      line-height: 26px;
    }
  }
  a {
    text-decoration: underline;
    color: #6d9e09;
  }
  h3 {
    text-transform: uppercase;
    display: inline-block;
    background-color: rgba(0,0,0,0.7);
    padding: 10px 15px;
    font-weight: 600;
    color: #fff;
    border-left: 4px solid #97c00a;
    margin-bottom: 35px;
  }
  h4 {
    text-transform: uppercase;
    display: inline-block;
    background-color: rgba(0,0,0,0.7);
    padding: 12px 18px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
  }
  em {
    display: block;
    margin-bottom: 5px;
    font-style: normal;
    color: #97c00a;
    font-size: 16px;
  }
}

.video-home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  video {
    min-width: 100%;
    min-height: 100%;
  }
}
.overlay-masked {
  background: rgba(0,0,0,.8);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;
  z-index: 1;
}
.contenido-video {
  width: 100%;
  position: absolute;
  top: 40%;
  margin: 0 auto;
  text-align: center;
  z-index: 999;
  h1 {
    font-size: 50px;
    color: #93d60a;
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
    text-shadow:3px 3px 3px #000;
    z-index: 99999;
  }
  h3 {
    font-family: $subtitulo-font;
    font-size: $subtitulo-size;
    color: #fff;
    font-weight: 300;
    text-align: center;
    display: block;
    margin: 35px 0;
    padding:0;
    text-shadow:3px 3px 3px #000;
  }
  .boton-ver-productos {
    font-family:$subtitulo-font;
    font-size: 17px;
    color: #fff;
    font-weight: 700;
    border:none;
    background-color: $color-primario;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.35s ease;
    -webkit-box-shadow: 8px 8px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 8px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 8px 8px 5px 0px rgba(0,0,0,0.75);
    &:hover  {
      color: #fff;
      text-decoration: none;
      opacity: .8;
      transition: all .4s;
    }
    h4 {
      text-shadow:2px 2px 2px #000
    }
  }
}
.linea-izquierda {
  width: 50%;
  height: 0px;
  border-right: 40px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 12px solid $color-primario;
  position: relative;
  float: left;
  z-index: 9;
}
.linea-derecha {
  width: 50%;
  height: 0px;
  border-right: 0px solid transparent;
  border-left: 40px solid transparent;
  border-top: 12px solid $color-secundario;
  position: relative;
  float: right;
  z-index: 9;
}

.wrapper-default {
  background-image: url('../../images/public/texture.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.texto-presentacion {
  background: rgba(0,0,0,.2);
  position: relative;
  padding: 90px 0;
  .subtitulo {
    font-family: $subtitulo-font;
    font-size: subtitulo-size;
    color: #eee;
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
    text-transform: uppercase;
    width: 70%;
    margin:0 auto;
    padding: 30px 0;
  }
  .parrafo{
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #eee;
    text-align: center;
    line-height: 1.5;
    width: 60%;
    margin:0 auto;
    padding: 0;
  }
  span {
    background-color: $color-primario;
    padding: 2px 6px;
    color: #333;
  }
}
.texto-presentacion-blanco {
  padding: 90px 0;
  position: relative;
  z-index: 99;
  .subtitulo {
    font-family: $subtitulo-font;
    font-size: $subtitulo-size;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 1.8;
    text-transform: uppercase;
    width: 60%;
    margin:0 auto;
    padding: 30px 0;
  }
  .parrafo{
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #fff;
    text-align: center;
    text-align: center;
    line-height: 1.5;
    width: 60%;
    margin:0 auto;
    padding: 0;
  }
  span {
    background-color: $color-primario;
    padding: 2px 4px;
  }
}

.parallax {
  width: 100%;
  height: 100% !important;
  background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  left: 0px;
  &:before {
    width: 100%;
    height: 55px;
    background: #fff;
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    margin-top: -35px;
    padding: 0;
    z-index: 3;
    transform: rotate(-1.7deg);
    -ms-transform: rotate(-1.7deg);
    -webkit-transform: rotate(-1.7deg);
    -o-transform: rotate(-1.7deg);
    -moz-transform: rotate(-1.7deg);
  }
  &:after {
    width: 100%;
    height: 55px;
    background: #fff;
    display: inline-block;
    content: "";
    position: absolute;
    bottom: 0;
    margin-bottom: -35px;
    padding: 0;
    z-index: 3;
    transform: rotate(-1.7deg);
    -ms-transform: rotate(-1.7deg);
    -webkit-transform: rotate(-1.7deg);
    -o-transform: rotate(-1.7deg);
    -moz-transform: rotate(-1.7deg);
  }
}
.categoria-productos {
  background-color:rgba(0,0,0,.8);
  padding: 70px 0;
  position: relative;
  z-index: 999;
}

.categoria-productos-gris {
  background-color:rgba(0,0,0,.6);
  position: relative;
  padding: 70px 0;
  z-index: 999;
}
.titulo-productos {
  width: 50%;
  margin: 0 auto;
  display: block;
  position: relative;
  transform: skewY(-2deg);
  -ms-transform: skewY(-2deg);
  -webkit-transform: skewY(-2deg);
  a{
    color: $color-primario;
    transform: skewY(2deg);
    -ms-transform: skewY(2deg);
    -webkit-transform: skewY(2deg);
    transition: all 0.35s ease;
  }
  h2 {
    font-family: $titulo-principal-font;
    font-size: $titulo-principal-size;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    text-shadow:3px 3px 3px #333;
    list-style-type: none;
    transition: all 0.35s ease;
    margin: 0 auto;
    padding: 15px 0;
  }
  h2:hover,
  a:hover {
    text-decoration: none;
    list-style-type: none;
    transform: scale(1.2);
  }
}
.subcategorias-productos {
  margin-top: 15px;
  h3{
    font-family: $subtitulo-font;
    text-align: center;
    font-size: $subtitulo-size;
    color: #eee;
    border-bottom:1px solid transparent;
    position: relative;
    top: 0;
    margin: 0 auto;
    width: 100%;
    padding: 3px;
    display: inline-block;
    z-index: 9999;
    transition: all 0.35s ease;
  }
  a {
    color: #eee;
    &:hover{
      color: $color-secundario;
      text-decoration: none !important;
      list-style: none !important;
    }
  }
}
.owl-carousel {
  .owl-stage-outer {
    box-shadow: 4px 4px 4px rgba(0,0,0,0.8)
  }
  .galeria-imagenes-home img {
    width: auto;
    height: 400px;
    max-height: 400px;
    display: block;
    overflow: hidden;
    object-fit: cover;
  }
  .owl-item {
    border: 4px solid #000;
  }
  .owl-item img {
    display: block;
    // width: 100%;
  }
  .owl-nav {
    top: 50%;
    position: absolute;
    margin-top: -20px;
    width: 100%;
    .owl-prev,
    .owl-next {
      width: 30px;
      height: 30px;
      display: inline-block;
      position: absolute;
      border-radius:0;
      background: $color-secundario;
      &:hover {
        background:rgba(0,0,0,.5);
      }
    }
    .owl-prev {
      left: -5px;
      background: $color-secundario;
      &:before {
        position: relative;
        left: -1px;
      }
    }
    .owl-next {
      right: -5px;
      background: $color-secundario;
      display: inline-block;
      position: absolute;
      border-radius:0;
      right: -5px;
      &:before {
        position: relative;
        left: 2px;
      }
    }
  }
}
.custom-arrows-style-1 {
  .owl-nav {
    .owl-prev,
    .owl-next {
      height: 80px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      font-weight: 900;
    }
    .owl-prev::before {
      content: "\f104";
      font-family: "Font Awesome 5 Free";
      top: 25px;
      font-size: 18px;
    }
    .owl-next::before {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      top: 25px;
      font-size: 18px;
    }
  }
}

.imagen {
  border: none;
  -webkit-backface-visibility: intiial;
  backface-visibility: intiial;
  -webkit-transform: initial;
  -moz-transform: initial;
  -ms-transform: initial;
  -o-transform: initial;
  transform: initial;
  .thumb-info-wrapper{
    margin: 0 !important;
    -webkit-backface-visibility: intiial;
    backface-visibility: intiial;
    -webkit-transform: initial;
    -moz-transform: initial;
    -ms-transform: initial;
    -o-transform: initial;
    transform: initial;
    &:after {
      background:rgba(131,175,56,.8);
    }
    &:hover {
      background: rgba(131,175,56,.8);
      display: block;
    }
    .thumb-info-plus {
      width: 10%;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: ease all 0.3s;
      -moz-transition: ease all 0.3s;
      transition: ease all 0.3s;
      &:before,
      &:after {
        width: 100%;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        border-top: 1px solid #2e353e;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      &:after {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        -moz-transform: translate(-50%, -50%) rotate(0deg);
        -ms-transform: translate(-50%, -50%) rotate(0deg);
        -o-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
  &:hover {
    .thumb-info-wrapper {
      margin: 0 !important;
      -webkit-backface-visibility: intiial;
      backface-visibility: intiial;
      -webkit-transform: initial;
      -moz-transform: initial;
      -ms-transform: initial;
      -o-transform: initial;
      transform: initial;
      &:after {
        opacity: 0.8;
        background:rgba(131,175,56,.8);
      }
      .thumb-info-plus {
        opacity: 1;
        width: 30%;
        &:after {
          -webkit-transform: translate(-50%, -50%) rotate(90deg);
          -moz-transform: translate(-50%, -50%) rotate(90deg);
          -ms-transform: translate(-50%, -50%) rotate(90deg);
          -o-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
}
.thumb-info-wrapper {
  margin: 4px;
  overflow: hidden;
  display: block;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  &:after {
    height: 100%;
    color: #FFF;
    background: rgba(23, 23, 23, 0.8);
    border-radius: 4px;
    content: "";
    position: absolute;
    left: -4px;
    right: -4px;
    top: 0;
    display: block;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
  }
}
.thumb-info .info-hover {
  margin: 4px;
  overflow: hidden;
  display: block;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.thumb-info-wrapper {
  margin: 4px;
  overflow: hidden;
  display: block;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.info-hover {
  border: none;
  -webkit-backface-visibility: intiial;
  backface-visibility: intiial;
  -webkit-transform: initial;
  -moz-transform: initial;
  -ms-transform: initial;
  -o-transform: initial;
  transform: initial;
  .info-hover-1 {
    margin: 0 !important;
    -webkit-backface-visibility: intiial;
    backface-visibility: intiial;
    -webkit-transform: initial;
    -moz-transform: initial;
    -ms-transform: initial;
    -o-transform: initial;
    transform: initial;
    &:after {
      content: "";
      background:rgba(131,175,56,.8);
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      border-radius: 4px;
      color: #FFF;
      left: -4px;
      right: -4px;
      top: 0;
      display: block;
      opacity: 0;
      z-index: 1;
    }
    &:hover,
    &:after {
      height: 250px;
      background: rgba(131,175,56,.8);
    }
  .mas-info {
    width: 10%;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    transition: ease all 0.3s;
    &:before,
    &:after {
      width: 100%;
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      border-top: 1px solid #2e353e;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    &:after {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      -moz-transform: translate(-50%, -50%) rotate(0deg);
      -ms-transform: translate(-50%, -50%) rotate(0deg);
      -o-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
}
  &:hover {
    .info-hover-1 {
      .mas-info {
        opacity: 1;
        width: 30%;
        &:after {
          -webkit-transform: translate(-50%, -50%) rotate(90deg);
          -moz-transform: translate(-50%, -50%) rotate(90deg);
          -ms-transform: translate(-50%, -50%) rotate(90deg);
          -o-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
      &:after {
        opacity: 0.8;
      }
    }
  }
}

.linea-top-titulo {
  position: relative;
  margin: 0 auto;
  top: 10px;
  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
}
.linea-bottom-titulo {
  position: relative;
  margin: 0 auto;
  top: -5px;
  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
}
.parallax-medios-pago {
  width: 100%;
  height: 100% !important;
  background-size: cover !important;
  background-attachment:fixed;
  position: relative;
  left: 0px;
  margin-bottom: 255px;
  &:before {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
}

.medios-pago {
  background-color: rgba(0,0,0,.2);
  h2{
    font-family: $titulo-principal-font;
    font-size: $titulo-principal-size;
    color: #fff;
    text-align: center;
    text-shadow:2px 2px 2px #333;
    margin: 0;
    padding: 35px 0 25px 0;
    position: relative;
  }
  img {
    margin: 40px 0;
    transition: all 0.35s ease;
    -webkit-transition:all .9s ease;
    &:hover {
      opacity: .5;
      transition: all .4s;
      transform:scale(1.25);
    }
  }
}

.seccion {
  width: 100%;
  position: relative;
}
.parallax-encabezado {
  height: 320px;
  background-color: rgba(0, 0, 0, 0.8);
  background-position-x:50%;
  background-attachment: fixed;
  background-size:cover;
  overflow: hidden;
  position: relative;
  top: 0;
  padding: 70px 0;
}
.titulo-encabezado {
  width: 40%;
  height: 80px;
  background-color: rgba(0,0,0,.5);
  position: relative;
  top: 80px;
  margin: 0 auto;
  display: block;
  transform: skewY(-2deg);
  -ms-transform: skewY(-2deg);
  -webkit-transform: skewY(-2deg);
  h2 {
    font-family: $titulo-principal-font;
    font-size: $titulo-principal-size;
    color: #fff;
    text-align: center;
    font-weight: lighter;
    font-weight: 100;
    line-height: 0.9;
    position: relative;
    right: 0;
    left:0;
    top: 10px;
    margin: 0 auto;
    display: table;
    padding: 10px 15px;
    transform: skewY(2deg);
    -ms-transform: skewY(2deg);
    -webkit-transform: skewY(2deg);
    text-shadow:3px 3px 3px #333;
  }
}
.imagen-hover-encabezado {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
}
figure {
  margin-top: 0;
}
.texto-nosotros {
  padding: 120px 0 65px 0;
  position: relative;
  .subtitulo {
    font-family: $subtitulo-font;
    font-size: $subtitulo-size;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
    text-transform: uppercase;
    width: 60%;
    margin:0 auto;
    padding: 30px 0;
  }
  .parrafo{
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #fff;
    text-align: center;
    text-align: center;
    line-height: 1.5;
    width: 60%;
    margin:0 auto;
    padding: 0;
  }
  span {
    background-color: $color-primario;
    padding: 2px 4px;
    color: #333;
  }
}
.quienes-somos {
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  display: block;
  z-index: 9999;
  padding: 50px 0;
  .img {
    position: absolute;
    top: 0px;
    right: 50%;
    bottom: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .img {
    right: 0px;
    left: 50%;
  }
}
.quienes-somos.right {
  .quienes-somos-imagen {
    right: 0px;
    left: 50%;
  }
}

.sombra-imagen{
  width: 100%;
  height: 100%;
  background: #7ED321;
  float: left;
  position: relative;
  top: 10px;
  right: 10px;
  display: block;
  z-index: -9;
  img {
    width: 100%;
    position: relative;
    bottom: 12px;
    left: 12px;
  }
}

.quienes-somos-texto {
  position: relative;
  left: 0;
  right: 0;
  p {
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #eee;
    text-align: left !important;
    line-height: 1.5;
    margin: 0 auto;
    padding: 0 20px 0 0;
  }
  h3 {
    color: $color-primario;
    font-size: $subtitulo-size;
  }
}
.mision,
.vision,
.valores {
  padding: 20px;
  height: 390px;
  z-index: 999;
}
.mision-vision {
  position: relative;
  padding: 70px 0;
  z-index: 999;
}
.mision-vision-icono {
  i {
    font-size: 35px;
    color: $color-primario;
    background-color: rgba(0,0,0,0.7);
    padding: 10px;
    display: inline-block;
    text-shadow: 2px 2px 2px #000;
  }
}
.mision-vision-texto {
  p {
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #eee;
    line-height: 1.5;
    text-align: left;
    margin: 0;
    padding:25px 0;
  }
}
.mision-vision-titulo {
  color: #fff;
  background-color:$color-primario;
  border-radius: 0px;
  line-height: normal;
  margin-bottom:15px;
  box-shadow: 8px 8px 5px 0px rgba(0,0,0,0.3);
  &:before {
    width: 0;
    height: 0;
    content:"";
    position: absolute;
    border-top: 20px solid $color-primario;
    border-right: 18px solid transparent;
    border-left: 18px solid transparent;
    margin: 35px 0 0 15px;
  }
  h3 {
    font-family: $subtitulo-font;
    font-size: $subtitulo-size;
    color: #fff;
    font-weight: 500;
    text-align: left;
    text-shadow: 2px 2px 2px #000;
    background-color: $color-primario;
    display: block;
    padding: 8px 0 8px 15px;
  }
}

.imagenes-producto {
  img {
    margin-bottom: 10px;
    border: 4px solid #000;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.8);
  }
}

.subtitulo-parrafo {
  font-size: 16px;
  font-weight: 600;
  margin-top: 25px;
}

.bloque-productos {
  color: #fff;
  padding: 25px;
  text-align: center;
  margin-top: 15px;
}

.carousel-img {
  height: 150px;
}

.parallax-innovacion {
  width: 100%;
  height: 415px;
  background-attachment:fixed;
  background-size: cover !important;
  position: relative;
  left: 0px;
}
.lightbox {
  margin-bottom: 255px;
}
.contenedor-breadcrumbs {
  width: 100%;
  background-color: #fff;
  padding-top: 30px;
}
.breadcrumbs {
  height: 40px;
  background-color: #edeef0;
  color: #FFF;
  line-height: 30px;
  text-align: center;
  box-shadow: none;
  padding: 5px 5px 5px 0px;
  display: inline-block;
  position: relative;
  z-index: 10;
  &:after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: -40px;
    top: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid #edeef0;
    z-index: -1;
  }
  a {
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: $color-primario;
    text-shadow: 1px 1px 1px #000;
    padding-left: 10px;
    transition: all 0.35s ease;
    &:hover {
      text-decoration: none;
      list-style: none;
      transition: all 0.3s;
    }
  }
  .volver {
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #fff;
    background-color: $color-secundario;
    padding: 9px;
    &:hover {
      background-color: $color-primario;
    }
  }
  span {
    margin: 0 8px;
    color: $color-primario;
  }
  i {
    padding-right: 10px;
  }
}
.innovacion {
  background-color: rgba(0,0,0,.5);
  position: relative;
  padding: 90px 0;
  p{
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #fff;
    text-align: center;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    padding: 25px 0;
  }
  img {
    width: 50%;
    display: block;
    margin: 0 auto;
    padding-bottom: 30px;
    position: relative;
  }
}

.main-container {
  padding: 120px 0 15px 0;
  background-image: url('../../images/public/texture-deck.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.listado-productos {
  padding-left: 0;
  margin-top: 25px;
  li {
    list-style-type: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    border-bottom: 1px solid transparent;
    padding: 0 3px 0 8px;
    margin-bottom: 10px;
    display: inline-block;
    i {
      color: $color-primario;
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.categorias-productos {
  background-color: #fff;
  overflow: visible;
  position: relative;
  margin-bottom: 275px;
  padding: 50px 0 70px 0;
}
.categoria-imagen {
  margin-bottom: 35px;
  &:hover{
    box-shadow: 0px 0px 30px 0px rgba(139, 139, 139, 0.5);
  }
}
.stock {
  font-family: $parrafo-font;
  font-size: $parrafo-size;
  color: #FFF;
  display: inline-block;
  text-align: center;
  line-height:2;
  text-shadow: 2px 2px 2px #000;
  box-shadow: none;
  background-color: $color-primario;
  border-bottom: 2px solid transparent;
  height:40px;
  position: absolute;
  top: 0;
  padding: 5px 5px 5px 15px;
  z-index: 10;
  &:after {
    content:"";
    position:absolute;
    right:-40px;
    width: 0;
    height: 0;
    top: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid $color-primario;
    z-index:-1;
  }
}
.sin-stock {
  font-family: $parrafo-font;
  font-size: $parrafo-size;
  color: #FFF;
  display: inline-block;
  text-align: center;
  line-height:2;
  text-shadow: 2px 2px 2px #000;
  box-shadow: none;
  background-color: $color-secundario;
  border-bottom: 2px solid transparent;
  height:40px;
  position: absolute;
  top: 0;
  padding: 5px 5px 5px 15px;
  z-index: 10;
  &:after {
    content:"";
    position:absolute;
    right:-40px;
    width: 0;
    height: 0;
    top: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid $color-secundario;
    z-index:-1;
  }
}
.bloque-imagen {
  max-width: none;
  height: 250px;
  overflow: hidden;
  position: relative;
  a{
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #000;
    text-decoration: none;
    list-style: none;
    text-align: center;
    img {
      max-width: none;
      max-height: 360px;
      background-size: cover;top: 50%;
      position: absolute;
      left: 50%;
      text-align: center;
     -webkit-transform:translate(-50%,-50%);
    }
  }
}

.nombre-producto {
  background-color: $color-secundario;
  border-top: 4px solid $color-primario;
  position: relative;
  &:before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: 45%;
    border-top: 10px solid $color-primario;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    margin: 0 auto;
    display: block;
  }
  h4 {
    font-family: $subtitulo-font;
    font-size: 18px;
    color: #fff;
    list-style: none;
    text-align: center;
    text-shadow: 2px 2px 2px #000;
    text-decoration: none;
    margin: 0;
    padding: 25px 0 25px 0px;
  }
}
.texto-datos-contacto {
  padding: 150px 0 0 0;
  position: relative;
  .subtitulo {
    font-family: $titulo-principal-font;
    font-size: $titulo-principal-size;
    color: #eee;
    font-weight: lighter;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 25px 0 ;
    line-height: 1.5;
    span {
      background-color: rgba(0,0,0,0.8);
      padding: 0 25px;
    }
  }
  p {
    font-family: $parrafo-font;
    font-size: 15px;
    color: #eee;
    text-align: center;
    line-height: 1.5;
    width: 60%;
    margin: 0 auto;
    padding: 0;
  }
  i {
    font-size: 35px;
    color: $color-primario;
    background-color: $color-secundario;
    text-shadow: 2px 2px 2px #000;
    padding: 10px;
    display: inline-block;
  }
}
.datos-contacto {
  text-align: center;
  padding: 70px 0;
  h3 {
    font-family: $subtitulo-font;
    font-size: $subtitulo-size;
    color: #eee;
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
  }
  i {
    font-size: 35px;
    color: $color-primario;
    background-color: rgba(0,0,0,0.8);
    text-shadow: 2px 2px 2px #000;
    padding: 10px;
    display: inline-block;
  }
}

.direccion {
  background-color:rgba(0,0,0,0.7);
  padding: 20px 0 40px 0;
  position: relative;
  h2 {
    font-size: 25px;
    color: #fff;
    text-align: center;
    text-shadow: 2px 2px 2px #000;
    margin: 0;
    padding: 15px 0;
    font-weight: 600;
  }
  h3 {
    font-family: $subtitulo-font;
    font-size: 16px;
    color: #fff;
    text-shadow: 2px 2px 2px #000;
    text-align: center;
    margin: 0;
  }
  i {
    font-size: 35px;
    color: $color-primario;
    background-color: rgba(0,0,0,0.8);
    text-shadow: 2px 2px 2px #000;
    margin: 0 auto;
    padding: 10px;
    display: table;
    position: relative;
    left: 0;
    right: 0;
    z-index: 999;
  }
}
.mapa {
  background: #edeef0;
  position: relative;
}
.divider {
  height: 1px;
  background-image: -webkit-linear-gradient(left, transparent, rgba(0,0,0,0.8), transparent);
  background-image: linear-gradient(to right, transparent, rgba(0,0,0,0.8), transparent);
  text-align: center;
  border: 0;
  margin: 45px 0 0 0;
  position: relative;
  clear: both;
  z-index: 99;
  .fa,
  .icons {
    width: 50px;
    height: 50px;
    background: rgba(0,0,0,0.8);
    font-size: 20px;
    color: $color-primario;
    text-align: center;
    line-height: 50px;
    text-align: center;
    border-radius: 0;
    display: inline-block;
    margin: 0 auto 0 -25px;
    position: absolute;
    top: -25px;
    left: 50%;
    z-index: 10;
    &:after,
     {
      width: 100%;
      height: 100%;
      box-sizing: content-box;
      content: "";
      display: block;
      padding: 1px;
      position: absolute;
      top: -4px;
      left: -4px;
    }
  }
}

iframe {
  border: 0px;
  display: block;
}
.texto-formulario {
  padding:50px 0 0 0;
  .subtitulo {
    font-family: $subtitulo-font;
    font-size: $subtitulo-size;
    color: #eee;
    text-align: center;
    line-height: 1.5;
    text-transform: initial;
    margin:0 auto;
    padding: 10px 0;
  }
}

.formulario {
  position: relative;
  padding: 80px 0 60px 0;
  input,
  textarea {
    font-family: $parrafo-font;
    font-size: 14px;
    color: #eee;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    padding: 10px 14px;
    margin: 0;
    border: 1px solid #444;
    margin-bottom: 14px;
    border-radius: 3px;
    outline: none;
  }
}

.btn-enviar {
  font-family: $parrafo-font;
  font-size: $parrafo-size;
  color: #333;
  text-align: center;
  font-weight: 700;
  background-color: $color-primario;
  transition: all 0.35s ease;
  margin: 0 auto;
  padding: 10px 20px;
  border:none;
  border-radius: 4px;
  display: block;
  box-shadow: 8px 8px 5px 0px rgba(0,0,0,0.3);
  transition: all 0.35s ease;
  &:hover {
    text-decoration: none;
    opacity: .8;
    transition: all .4s;
    background-color: lighten($color-primario, 15%);
  }
}

.consulta-enviada {
  font-family: $subtitulo-font;
  font-size: $subtitulo-size;
  color: $color-primario;
  text-align: center;
  line-height: 1.5;
  text-transform: initial;
  padding: 100px 0;
  i {
    font-size: 35px;
    color: $color-primario;
    background-color: rgba(0,0,0,0.8);
    text-shadow: 2px 2px 2px #000;
    margin: 0 auto;
    padding: 10px;
    display: table;
    position: relative;
    left: 0;
    right: 0;
    z-index: 999;
  }
}

.parallax-contacto {
  width: 100%;
  height: 100% !important;
  background-attachment:fixed;
  background-size: cover !important;
  margin-bottom: 275px;
  position: relative;
  left: 0px;
  &:before {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
}

footer {
  width: 100%;
  background-color: #000;
  overflow: hidden;
  background-size: cover;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -999;
}
.logo-footer {
  padding-top: 25px;
}
.datos-contacto-footer {
  display: block;
  overflow: hidden;
  padding: 45px 0;
}
.telefono-direccion-footer {
  h3 {
    font-family: $subtitulo-font;
    font-size: $subtitulo-size;
    color: #fff;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 10px;
    padding: 5px 0;
    display: inline-block;
    border-bottom: 1px solid $color-primario;
  }
  p {
    font-family: $parrafo-font;
    font-size: $parrafo-size;
    color: #fff;
    text-align: left;
    line-height: 1.5;
    margin: 0 auto;
    padding: 0;
  }
  i {
    font-size: 15px;
    color: $color-primario;
    background-color: $color-secundario;
    display: inline-block;
    margin-right: 5px;
    padding: 10px;
  }
}
.linea-izquierda-footer {
  width: 25%;
  height: 0px;
  border-right: 40px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 12px solid $color-primario;
  float: left;
  position: relative;
  top: -10px;
  z-index: 9;
}
.linea-derecha-footer {
  width: 75%;
  height: 0px;
  border-right: 0px solid transparent;
  border-left: 40px solid transparent;
  border-top: 12px solid $color-secundario;
  float: right;
  position: relative;
  top: -10px;
  z-index: 9;
}
.copyright {
  background: $color-secundario;
  display: block;
  &:before {
    width: 0;
    height: 0;
    content: "";
    margin: 0 auto;
    display: block;
    position: absolute;
    right:0;
    left: 0;
    border-top: 10px solid #000;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
  p {
    font-family: $parrafo-font;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 1.5;
    margin: 0 auto;
    padding: 20px 0;
  }
}
.fa-chevron-up:before {
  content: none;
}

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}


@media (max-width: 991px) {
  .logo img { height: 50px; margin-left: 15px; margin-top:27px; }
  .scrolled .logo img { margin-top:27px; }
  .quienes-somos .img { position: static; top: auto; right: auto; bottom: auto; left: auto; height: 440px;}
  .mision, .vision, .valores {height: auto;}
  .innovacion img {width: 70%;}
  .datos-contacto-footer { text-align: center; padding: 25px 0; }
  .telefono-direccion-footer p  { text-align: center; }
  .linea-izquierda-footer, .linea-derecha-footer {width: 50%;}
  footer {position: sticky;}
  .logo-footer img {margin: 0 auto;}
  .main-container { padding: 0 }
}

@media (min-width: 776px) and (max-width: 991px) {
  .logo .logo-blanco {
    margin-top: 25px;
  }
  .scrolled .logo img {
    margin-top: 16px;
  }
  ul.menu li a {
    padding: 40px 8px;
  }
}

@media (max-width: 768px) {
  body {padding-top:60px;}
  .navbar { background-color:#000!important; padding: 0; border: none; position: fixed; z-index: 999999; border-bottom:1px solid transparent;}
  .logo img, .scrolled .logo img, .logo .logo-blanco, .logo-blanco img, .scrolled .logo-blanco img { height: 40px; margin-left: 15px; margin-top:12px; }
  .hamburger--collapse-r { display: block; }
  ul.menu  { height: 100vh; padding: 0; margin: 0; float: none;
    .red-social {
      display: none;
    }
  }
  ul.menu li { float: none; }
  ul.menu li a {float: none; padding: 18px 15px; }
  .navbar-collapse { background-color: rgba(0,0,0,.8); padding: 0; border-top:0; }
  .menu-toggle { display: block; }
  .contenido-video {top:30%;}
  .contenido-video h3 { font-size: 18px}
  .contenido img {margin: 0 auto; position: relative;top: 50px;}
  .texto-presentacion { padding: 50px 20px;}
  .thumb-info {height: auto;}
  .mision, .vision, .valores {margin-bottom: 25px;}
  .bloque-imagen a img {max-height: none;}
  .bloque-imagen a img {width:100%;}
  .copyright p {padding: 15px 0; font-size: 11px;}
  .titulo-encabezado {top:50px;}
  .texto-datos-contacto {padding-top: 35px; padding-right: 20px; padding-left: 20px}
  .direccion { margin-top: 40px; padding-right: 20px; padding-left: 20px}
  ul.menu .menu-desplegable > a {
    display: none;
  }
  ul.menu .sub-menu {
    visibility: visible;
    position: relative;
    top: inherit;
    left: inherit;
    opacity: 1;
    width: 100%;
    a {
      background-color: transparent!important;
      text-transform: uppercase;
      padding: 18px 15px!important;
    }
  }
}

 @media (max-width: 600px) {
  ul.menu  { height: 100vh; padding: 0; margin: 0; float: none; }
  ul.menu li { float: none; }
  ul.menu li a {float: none; }
  .contenido-video {position: absolute; top: 25%;}
  .contenido-video h1 {font-size:30px; padding:0 10px;}
  .contenido-video h3 {padding:0 10px;}
  .titulo-encabezado { width: 100%; }
  .titulo-productos {width: 100%;}
  .titulo-productos h2 {font-size: 25px;}
  .medios-pago h2 { font-size: 30px; }
  .texto-presentacion .subtitulo {width: 100%;}
  .texto-presentacion .parrafo {width: 100%;}
  .texto-nosotros .subtitulo {width: 100%;}
  .texto-nosotros .parrafo {width: 100%;}
  .innovacion p {width: 100%;}
  .bloque-imagen a img {width:auto;}
  .texto-formulario .subtitulo {width:100%;}
  .datos-contacto { padding: 35px 0 10px 0; }
  .texto-datos-contacto .subtitulo {width:100%; font-size: 30px; line-height:1; padding-bottom: 15px;}
  .texto-datos-contacto p {width:100%;}
}

@media (max-width: 500px) {
  ul.menu a:hover::before,
  ul.menu .active a::before,
  ul.menu a:hover::after,
  ul.menu .active a::after { width: 0%; }
  ul.menu a:before,
  ul.menu a::after { background-color: transparent; }
  .innovacion img {width: 100%;}
  .breadcrumbs a.volver {font-size: 10px; padding: 15px 5px 10px 5px }
  .breadcrumbs a {font-size: 10px; }
  .direccion h2 {font-size: 30px; }
  .telefono-direccion-footer h3 {font-size: 18px;}
  .telefono-direccion-footer p {font-size:12px;}
  .copyright p {font-size:12px;}
  .logo-footer img {width:70%;}
}

.btn-wsp{
  position:fixed;
  width:60px;
  height:60px;
  line-height: 63px;
  bottom:25px;
  right:25px;
  background:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:35px;
  box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
  z-index:1000;
  transition: all 300ms ease;
  &:hover{
    background: #fff;
    z-index: 1000;
  }
  &::after {
    content: '';
    width: 55px;
    height: 55px;
    border-radius: 100%;
    border: 3px solid #25d366;
    position: absolute;
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 5s infinite;
  }
  &:hover i::before {
    color: #25d366;
    transform: scale(1.1);
    transition: all .5s ease;
  }
}
