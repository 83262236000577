/* Colores */

$color-primario: #93d60a;
$color-secundario:#55585d;
$color-terciario:#303030;

/* Titulos */

$titulo-principal-font: 'Ramabhadra', sans-serif;
$titulo-principal-size:35px;
$subtitulo-font: 'Nunito Sans', sans-serif;
$subtitulo-size: 23px;
$parrafo-font:'Nunito Sans', sans-serif;
$parrafo-size: 17px;

/* Plugins */

$owl-image-path: '~owl.carousel/src/img/';
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$simple-line-font-path: "~simple-line-icons/fonts/" !default;
